































import api from "@/api";
import { Component, Vue } from "vue-property-decorator";
import { CustomFormType, SelectListItem } from "@/api/appService";

interface ProjectCustomFormItem {
  formId?: string;
  text: string;
  hostId: string;
  hostType: CustomFormType | undefined;
}
@Component({
  name: "PartnerCustomForm",
})
export default class PartnerCustomForm extends Vue {
  items: ProjectCustomFormItem[] = [];
  hostId!: string;

  async created() {
    if (this.$route.query.id) {
      this.hostId = String(this.$route.query.id);
    }

    await this.fetchEnum();
  }

  async fetchEnum() {
    await api.enumService
      .getValues({ typeName: "CustomFormType" })
      .then((res: any) => {
        if (res && res.length > 0) {
          res.shift();
        }
        this.items = res!.map((item: SelectListItem) => {
          return {
            text: item.text,
            hostId: this.hostId!.toString(),
            formId: "",
            hostType: item.value,
          } as ProjectCustomFormItem;
        });
        this.items.forEach((item) => {
          this.checkCustomForm(item);
        });
      });
  }
  checkCustomForm(item: ProjectCustomFormItem) {
    api.customFormService
      .getCustomFormId({
        hostType: item.hostType,
        hostId: item.hostId,
      })
      .then((res) => {
        item.formId = res;
      });
  }
  handleClick(item: ProjectCustomFormItem) {
    if (item.formId) {
      this.$router.push({
        name: "customFormDesign",
        query: {
          formId: item.formId,
          hostType: item.hostType,
        },
      });
    } else {
      this.$router.push({
        name: "customFormDesign",
        query: {
          hostId: item.hostId,
          hostType: item.hostType,
        },
      });
    }
  }
}
